@keyframes slide_in {
  0% {
    transform: translateX(100vw);
  };
  100%{
    transform: translateX(0);
  }
}

.slide-container div {
  animation: slide_in 0.4s ease-in-out both;
}

.slide-container div:nth-child(1) { animation-delay: .2s }
.slide-container div:nth-child(2) { animation-delay: .3s }
.slide-container div:nth-child(3) { animation-delay: .4s }
.slide-container div:nth-child(4) { animation-delay: .5s }
.slide-container div:nth-child(5) { animation-delay: .6s }
.slide-container div:nth-child(6) { animation-delay: .7s }